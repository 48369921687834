import React, {useState} from "react"
import Footer from "../components/Footer/Footer";

const styles = require("../styles/pages/Contact.module.scss");

export default function DemoPage() {
    return (
        <div className={styles.Contact}>

            <iframe style={{border: 0, boxShadow:'none', minHeight:'1000px'}} width="100%" height="100%" src="https://calendly.com/prestavi/demo?hide_event_type_details=1&hide_gdpr_banner=1&text_color=44506e&primary_color=2253c7"></iframe>

            <Footer />
        </div>
    )
}

